<template>
  <v-row style="justify-content: center">
    <covered-loader v-if="loading" />
    <v-col md="6">
      <v-card class="card text-center font-family-raleway-medium">
        <h3 class="card-text mb-3 font-family-raleway-medium">
          {{ $t("create_campaign_page.approve_edit_step_title") }}
        </h3>
        <div class="text-center font-raleway-regular" v-if="loading">
          {{ $t("loading") }} ...
        </div>
        <div
          class="text-center d-flex align-content-center justify-center mt-5"
          v-if="shouldRefresh && validatedText !== 'OK'"
        >
          <v-btn outlined @click="setAds" small> {{ $t("retry") }} </v-btn>
          <v-btn outlined @click="clickCreateManually" class="ml-2" small>
            {{ $t("create_manually_btn") }}
          </v-btn>
        </div>
        <div
          v-if="getIsError || isNotValid"
          class="font-family-raleway-medium text-left pa-2"
          style="
            font-size: 14px;
            color: red;
            background-color: rgb(255, 232, 232);
            width: 100%;
          "
        >
          {{ $t("create_campaign_page.make_sure") }}
        </div>
        <div v-if="!loading">
          <v-form
            class="text-left mt-2"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <h4 class="font-family-raleway-bold" v-if="!shouldRefresh">
              {{ $t("create_campaign_page.headlines") }}
              ( {{ headlines.length }} )
            </h4>
            <!--Headlines-->
            <div v-for="(item, index) of headlines" :key="index">
              <input
                v-model="item.title"
                max="30"
                @input="changeHead"
                class="input-border textarea-style mt-3 pa-3 font-family-raleway-medium"
                :class="
                  item.title.length > 30 ||
                  isHaveSameContent(item, index, 'headlines') ||
                  item.title.length === 0
                    ? 'error-border'
                    : ''
                "
              />
              <v-row
                class="ma-0 d-flex justify-space-between font-family-raleway-medium px-2"
              >
                <span
                  style="font-size: 12px; color: red"
                  v-if="item.title.length === 0"
                  >{{ $t("required_text") }}</span
                >
                <span
                  style="font-size: 12px; color: red"
                  v-if="isHaveSameContent(item, index, 'headlines')"
                  >Duplicate assets!</span
                >

                <span style="font-size: 12px; color: red">{{
                  item.title.length > 30
                    ? "Length of headline must not exceed 30 characters"
                    : ""
                }}</span>
                <span
                  :class="item.title.length > 30 ? 'color-red' : ''"
                  style="font-size: 12px"
                  >{{ item.title.length }}/30</span
                >
              </v-row>
            </div>
            <h4 class="font-family-raleway-bold mt-4" v-if="!shouldRefresh">
              {{ $t("create_campaign_page.descriptions") }}
              ( {{ descriptions.length }} )
            </h4>
            <!--Description-->
            <div v-for="(item, index) of descriptions" :key="index">
              <textarea
                v-model="item.title"
                class="input-border textarea-style mt-2 pa-3 font-family-raleway-medium"
                @input="changeDesc"
                :rows="3"
                :class="
                  item.title.length > 90 ||
                  item.title.length === 0 ||
                  isHaveSameContent(item, index, 'descriptions')
                    ? 'error-border'
                    : ''
                "
              />
              <v-row
                class="ma-0 d-flex justify-space-between px-4 font-family-raleway-medium"
              >
                <span
                  style="font-size: 12px; color: red"
                  v-if="item.title.length === 0"
                  >{{ $t("required_text") }}</span
                >

                <span
                  style="font-size: 12px; color: red"
                  v-if="isHaveSameContent(item, index, 'descriptions')"
                  >Duplicate assets!</span
                >
                <span style="font-size: 12px; color: red">{{
                  item.title.length > 90
                    ? "Length of description must not exceed 90 characters"
                    : ""
                }}</span>
                <span
                  :class="item.title.length > 90 ? 'color-red' : ''"
                  style="font-size: 12px"
                  >{{ item.title.length }}/90</span
                >
              </v-row>
            </div>
            <v-row v-if="validatedText !== 'OK' && validatedText !== ''">
              <v-col cols="12" v-if="!shouldRefresh">
                <span class="color-red font-family-raleway-medium"
                  >{{ validatedText }}: {{ errorTopic }}</span
                >
                <span class="font-family-raleway-medium" v-if="errorReasons">
                  <br />
                  This the cause of the error
                  <span class="color-red">
                    "<span
                      v-for="item in errorReasons"
                      :key="item"
                      class="color-red"
                      >{{ item }}</span
                    >"
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!shouldRefresh">
              <v-col class="text-right mt-2" cols="12">
                <v-btn
                  class="button save-btn font-family-raleway-medium"
                  height="42"
                  width="150"
                  @click="handleSaveChanges"
                  :loading="isSaving"
                  :disabled="getIsError"
                  >{{ $t("save_button") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-row>
          <v-col cols="12" class="mt-3">
            <v-chip
              style="width: 100%; background-color: rgb(242 255 247)"
              v-if="isValidated && !isSaving && !form"
              class="py-5"
              size="large"
              text-color="green"
              label
            >
              <span class="font-family-raleway-medium">{{
                $t("create_campaign_page.could_moveon_next_text")
              }}</span>
            </v-chip>
          </v-col>
          <v-col md="12" class="text-center" v-if="isSaving">
            <span>{{ $t("create_campaign_page.please_wait") }}</span>
          </v-col>
          <v-col class="text-left" cols="12">
            <h4 class="font-family-raleway-medium align-center d-flex">
              <img :src="phoneIcon" alt="" class="mr-2" />
              {{ $t("create_campaign_page.phone_number_input") }}
            </h4>
          </v-col>
          <v-col class="py-0" cols="12" md="4">
            <v-select
              v-model="countryName"
              :items="selectItems"
              class="input-border font-family-raleway-medium"
              item-text="name"
              item-value="alpha2"
              outlined
              @input="changeCode"
            ></v-select>
          </v-col>
          <v-col class="my-0 py-0" cols="12" md="8">
            <v-text-field
              class="input-border font-family-raleway-medium"
              v-model="phoneVal"
              style="width: 350px"
              outlined
            >
              <template v-slot:prepend-inner>
                <span
                  style="font-size: 17px; margin-top: 2px"
                  class="font-family-raleway-medium"
                >
                  {{ phoneCode }}
                </span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center justify-end pb-10 pt-5" v-if="!form">
          <v-col md="6">
            <v-btn
              class="back-btn text-decoration-underline"
              @click="handleClickBack"
            >
              {{ $t("back_button") }}
            </v-btn>
          </v-col>
          <v-col md="6" class="pr-5">
            <v-btn
              class="button font-weight-bold next-btn font-family-raleway-medium"
              @click="handleClickNext"
              :disabled="!isValidated || isSaving"
              height="60"
              style="width: 230px !important"
            >
              {{ $t("create_campaign_page.next_button") }}
            </v-btn>
          </v-col>
        </v-row>
        <terms-ads-modal
          :dialog="termsAdsModal"
          @closeModal="termsAdsModal = false"
        />
        <create-manually-ads
          :dialog="createManualDialog"
          @setData="setAdsData"
          @close="createManualDialog = false"
        />
      </v-card>
    </v-col>
    <v-col md="5">
      <v-card class="card text-center font-family-raleway-medium">
        <div class="text-center pb-5">
          <h3 class="card-text mb-3 font-family-raleway-medium">
            {{ $t("create_campaign_page.preview") }}
          </h3>
          <v-list v-if="loading">
            <div class="text-center font-raleway-regular">
              {{ $t("loading") }} ...
            </div>
            <v-skeleton-loader
              max-width="100%"
              class="mt-1"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-list>
          <div class="text-left" v-if="!loading">
            <span class="url-styles text-left">{{ campaign.url }}</span>
          </div>
          <div class="text-center my-5" v-if="isSaving">
            {{ $t("create_campaign_page.please_wait") }}
          </div>
          <!--headlines-->
          <div class="text-left py-1" v-if="!isSaving">
            <span
              class="align-center"
              v-for="(item, index) of previewData.headlines"
              :key="index"
            >
              <a
                :style="
                  index + 1 === previewData.headlines?.length
                    ? ''
                    : 'border-right: 2px solid #0057FF!important;'
                "
                class="text-decoration-none color-blue font-family-raleway-medium"
                :class="index === 0 ? 'pr-2' : 'px-2'"
                ><strong>{{ item.title }}</strong></a
              >
            </span>
          </div>

          <!--descriptions-->
          <div class="pt-2 text-left" v-if="!isSaving">
            <span
              class="align-center"
              v-for="(item, index) of previewData.descriptions"
              :key="index"
            >
              <a
                :style="
                  index + 1 === previewData.descriptions?.length
                    ? ''
                    : 'border-right: 1px solid black'
                "
                class="text-decoration-none color-black url-styles"
                :class="index === 0 ? 'pr-2' : 'px-2'"
                >{{ item.title }}</a
              >
            </span>
          </div>
        </div>
        <!--        <v-chip-->
        <!--          style="width: 100%; background-color: rgb(242 255 247)"-->
        <!--          v-if="isValidated && !isSaving && !form"-->
        <!--          class="py-5"-->
        <!--          size="large"-->
        <!--          text-color="green"-->
        <!--          label-->
        <!--        >-->
        <!--          <span class="font-family-raleway-medium">{{-->
        <!--            $t("create_campaign_page.could_moveon_next_text")-->
        <!--          }}</span>-->
        <!--        </v-chip>-->
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { campaignService } from "@/services/campaign";
import phoneIcon from "../../assets/icon/phone-icon.svg";
import axios from "axios";
const { collectionsService } = require("../../services/collections");

export default {
  name: "ApproveOrEdit",
  components: {
    CoveredLoader: () => import("@/components/CoveredLoader"),
    CreateManuallyAds: () => import("@/components/dialogs/CreateManuallyAds"),
    TermsAdsModal: () => import("@/components/dialogs/TermsAdsModal")
  },
  data: () => ({
    loading: false,
    isValidated: false,
    addsData: [],
    headlines: [],
    descriptions: [],
    previewData: {
      headlines: [],
      descriptions: [],
    },
    selectedItem: {
      description: "",
      headline: "",
    },
    valid: true,
    form: false,
    isSaving: false,
    isNotValid: false,
    validationComponent: true,
    createManualDialog: false,
    isHaveErrors: false,
    termsAdsModal: false,
    shouldRefresh: false,
    dialog: false,
    validatedText: "",
    errorTopic: "",
    errorReasons: [],
    text: "Yes this is for requiring texts required",
    titleRules: [(v) => v.length > 30 || this.$t("required_text")],
    descRules: [(v) => v.length > 90 || this.$t("required_text")],
    countryName: "",
    selectItems: [],
    campaign: {},
    phoneVal: "",
    phoneCode: "",
    phoneIcon,
  }),
  async created() {
    const responsePhones = await collectionsService.getPhoneCodes();
    this.selectItems = responsePhones?.data.map((item) => {
      if (item.name === "Canada") {
        return {
          name: item.name,
          dialCodes: ["1 "],
        };
      } else {
        return {
          ...item,
        };
      }
    });
    responsePhones?.data.forEach((item) => {
      if ("United States of America" === item.name) {
        this.countryName = item?.alpha2;
        this.phoneCode = "+" + item?.dialCodes[0];
        this.alphaCode = item?.alpha2;
      }
    });
    await this.setAds();
  },
  methods: {
    clickCreateManually() {
      this.createManualDialog = true;
      this.previewData.headlines = this.headlines;
      this.previewData.descriptions = this.descriptions;
    },
    changeHead() {
      this.isValidated = false;
    },
    changeDesc() {
      this.isValidated = false;
    },
    async setAds() {
      this.headlines = [];
      this.descriptions = [];
      this.isNotValid = false;
      this.shouldRefresh = false;
      await this.getAdsData();
      if (this.shouldRefresh) return;
      await this.validateAds();
      const findExistWrongTitle = this.addsData.find(
        (item) => item.title.length > 30
      );
      const findExistWrongDesc = this.addsData.find(
        (item) => item.description.length > 90
      );
      if (findExistWrongTitle || findExistWrongDesc) {
        this.isHaveErrors = true;
      }
      this.previewData.headlines = JSON.parse(JSON.stringify(this.headlines));
      this.previewData.descriptions = JSON.parse(
        JSON.stringify(this.descriptions)
      );
    },
    async validateAds() {
      const headlines = this.headlines.map((item) => item.title);
      const descriptions = this.descriptions.map((item) => item.title);
      this.isSaving = true;
      try {
        await campaignService.validateAds({
          headlines,
          descriptions,
        });
        this.validatedText = "OK";
        this.isValidated = true;
        this.isNotValid = false;
        localStorage.setItem(
          "campaign",
          JSON.stringify({
            ...this.campaign,
            headlines: this.headlines,
            descriptions: this.descriptions,
          })
        );
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log("Request canceled:", e.message);
        } else {
          const errCode = e.response?.data.errorCode;
          const errorReasons = e.response?.data?.policyErrorFields;
          const errorTopic = e.response?.data?.topic;
          this.errorReasons = errorReasons;
          this.errorTopic = errorTopic;
          this.validatedText = errCode || this.$t("something_wrong");
          this.isNotValid = true;
          if (this.headlines.length < 3 || this.descriptions.length < 2) {
            this.$notify({
              group: "foo",
              type: "error",
              title: this.$t("something_wrong"),
            });
            this.isNotValid = false;
            this.isSaving = false;
          }
        }
      }
      this.isSaving = false;
    },
    async setAdsData(data) {
      const { headlines, descriptions } = data;
      this.headlines = headlines;
      this.descriptions = descriptions;
      this.previewData.headlines = headlines;
      this.previewData.descriptions = descriptions;
      this.validatedText = "OK";
      this.isValidated = true;
      this.shouldRefresh = false;
    },
    async getAdsData() {
      this.loading = true;
      const campaign = localStorage.getItem("campaign");
      const allKeywords = localStorage.getItem("allKeywords");
      const parseToObj = JSON.parse(campaign);
      const parseKeywords = JSON.parse(allKeywords);
      this.campaign = parseToObj;
      const keys = parseToObj?.keywords;
      const payload = {
        companyName: parseToObj?.name,
        keyWords: parseToObj?.keywords,
        allKeyWords: parseKeywords,
        companyUrl: parseToObj.url,
        companyLocation: parseToObj.campaignTargets?.country,
        lang: this.$i18n.locale,
      };
      if (parseToObj.headlines && parseToObj.descriptions) {
        this.headlines = parseToObj.headlines;
        this.descriptions = parseToObj.descriptions;
      } else {
        if (keys.length > 0) {
          this.loading = true;
          try {
            const responseAdOptions = await campaignService.getHistories(
              payload
            );
            const array = responseAdOptions.data?.headers;
            const arrayDescs = responseAdOptions?.data?.descriptions;
            const removeDuplicates = (arr) => {
              return arr.reduce(function (acc, curr) {
                if (!acc.includes(curr)) acc.push(curr);
                return acc;
              }, []);
            };
            if (array.length < 3 || arrayDescs.length < 2) {
              if (array?.length >= 3) {
                this.headlines = array.map((item) => {
                  return {
                    title: item,
                  };
                });
              } else {
                let headlinesInputs = [
                  { title: "" },
                  { title: "" },
                  { title: "" },
                ];
                for (let i = 0; i < array.length; i++) {
                  if (i < headlinesInputs.length) {
                    headlinesInputs[i].title = array[i];
                  }
                }
                this.headlines = headlinesInputs;
              }
              if (arrayDescs?.length >= 2) {
                this.descriptions = arrayDescs.map((item) => {
                  return {
                    title: item,
                  };
                });
              } else {
                let descriptionsInputs = [{ title: "" }, { title: "" }];
                for (let i = 0; i < arrayDescs.length; i++) {
                  if (i < descriptionsInputs.length) {
                    descriptionsInputs[i].title = arrayDescs[i];
                  }
                }
                this.descriptions = descriptionsInputs;
              }
            } else {
              this.headlines = array
                .map((item) => {
                  return {
                    title: item,
                  };
                })
                .slice(0, 15);
              this.descriptions = responseAdOptions?.data?.descriptions
                ?.map((item) => {
                  return {
                    title: item,
                  };
                })
                .slice(0, 4);
              if (removeDuplicates(array).length !== array.length) {
                this.validatedText = "There are duplicate items!";
              }
            }
          } catch (err) {
            // const errCode = err.response?.statusText;
            this.$notify({
              group: "foo",
              type: "error",
              title: this.$t("something_wrong"),
            });
            this.shouldRefresh = true;
          }
          this.loading = false;
        }
      }
      this.loading = false;
    },
    editItem(val) {
      this.form = true;
      this.selectedItem = val;
    },
    clearHeadsDescs() {
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      delete parseToObj.headlines;
      delete parseToObj.descriptions;
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...parseToObj,
        })
      );
    },
    handleClickBack() {
      this.clearHeadsDescs();
      this.$router.push("show-address");
    },
    handleClickCancel() {
      this.validateAds();
      this.form = false;
    },
    async handleSaveChanges() {
      await this.validateAds();
      if (this.validatedText === "OK") {
        this.previewData.headlines = this.headlines;
        this.previewData.descriptions = this.descriptions;
      }
    },
    handleClickNext() {
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...this.campaign,
          phoneNumber: this.phoneVal,
          countryCode: this.alphaCode,
          headlines: this.headlines,
          descriptions: this.descriptions,
        })
      );
      this.$router.replace("review-company");
    },
    changeCode(val) {
      this.alphaCode = val;
    },
  },
  watch: {
    countryName(val) {
      const findItem = this.selectItems.find((item) => item?.alpha2 == val);
      this.alphaCode = val;
      this.phoneCode = "+" + findItem?.dialCodes[0];
    },
  },
  computed: {
    isHaveSameContent: function () {
      return function (item, index, items) {
        if (item.title === "") return false;
        const filteredItems = this[items].filter((i, ind) => ind !== index);
        return !!filteredItems.find((i) => i.title === item.title);
      };
    },
    getNotEditAddData() {
      return this.addsData.filter((item) => !item.isEdit);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getIsError() {
      if (this.headlines && this.descriptions) {
        const findExistWrongTitle = this.headlines.find(
          (item) => item.title.length > 30 || item.title.length === 0
        );
        const findExistWrongDesc = this.descriptions.find(
          (item) => item.title.length > 90 || item.title.length === 0
        );
        if (findExistWrongTitle || findExistWrongDesc) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/style/style.scss";

.edit-btn {
  text-transform: capitalize !important;
  background-color: transparent !important;
  box-shadow: none;
}

.save-btn {
  text-transform: capitalize !important;
  padding: 0 35px;
}

.list-item {
  .subtitle {
    color: #0057ff;
    font-size: 17px;
  }

  p {
    font-size: 15px;
  }
}

.url-styles {
  font-family: "Raleway regular", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 24px;
}
</style>
